import Vue from 'vue'
import Vuex from 'vuex'
import navigation from './navigation'
import realizations from './realizations'
import projects from './projects'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navigation,
    realizations,
    projects
  }
})
