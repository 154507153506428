<template>
  <section class="page page__contact">
    <div class="page__contact-container">
      <div class="page__contact-info">
        <h2>Kontakt</h2>

        <a href="tel:48790315707">Klaudia +48 790 315 707</a>
        <a href="mailto:kontakt@tkwnetrza.pl">kontakt@tkwnetrza.pl</a>
      </div>
      <div class="page__contact-company">
        <h2>Dane firmy</h2>
        <span>Projektowanie wnętrz Klaudia Tomczyk</span>
        <span>ul. Sienkiewicza 10c</span>
        <span>32-400 Myslenice</span>
        <span class="spacer">NIP: 681-20-65-352</span>
        <span>REGON: 365961455</span>
      </div>
    </div>

    <div class="page__contact-form">
      <h2>Formularz kontaktowy</h2>
      <div class="page__contact-form-head">
        Jeśli chcecie Państwo z nami współpracować lub macie pytania, zapraszamy
        do kontaktu.
      </div>

      <form>
        <fieldset>
          <label>
            <span>Imię i nazwisko</span>
            <input type="text" />
          </label>
          <label>
            <span>Adres e-mail</span>
            <input type="email" />
          </label>
          <label>
            <span>Telefon</span>
            <input type="tel" />
          </label>
          <label>
            <span>Wiadomość</span>
            <textarea></textarea>
          </label>
          <button class="btn btn-submit"><span>Wyślij</span></button>
        </fieldset>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style lang="scss">
.page__contact {
  padding: 0 4rem 4vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto !important;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }

  &-head {
    padding: 0 4rem;
    height: calc(5vw + 0.6rem);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    h2 {
      margin: 0 !important;
    }
  }

  &-container {
    flex: 1;
    width: 45%;
    border-right: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-content: center;

    @media screen and (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      border: 0;
    }
  }

  &-hours {
    padding: 2rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &-day,
    &-hour {
      padding: 0.25rem 0;
      width: 50%;
    }
  }

  &-info {
    padding: 15vh 0 0 4rem;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      padding: 0rem;
      height: auto;
    }

    h1,
    h3,
    h4 {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    a {
      color: var(--main-color);
      margin-bottom: 0.25rem;
      font-weight: 400;
      display: flex;
      padding: 0.1rem 0 0.25rem;
      position: relative;
      width: auto;

      &::after {
        content: '';
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0%;
        left: 0%;
        right: 100%;
        transition: var(--transition);
      }

      &:hover {
        left: 0%;
        right: 0%;

        &::after {
          animation-duration: 0.5s;
          animation-name: menulink;
          animation-iteration-count: 1;
          left: 0%;
          right: 0%;
        }
      }
    }
  }

  &-company {
    height: 50%;
    width: 100%;
    padding: 15vh 0 0 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid var(--main-color);

    @media screen and (max-width: 768px) {
      padding: 2rem 0;
      height: auto;
      border: 0;
    }

    span {
      padding: 0.1rem 0;
      line-height: 1.7;

      &.spacer {
        padding-top: 1rem;
      }
    }
  }

  &-form {
    flex: 1;
    width: 55%;
    padding: 15vh 4vw 5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    border-bottom: 1px solid var(--main-color);

    @media screen and (min-width: 2000px) {
      padding-bottom: 0rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      padding: 2rem 1rem;
      border-bottom: 0;
      border-top: 1px solid var(--main-color);
      margin: 0 -1rem;
    }

    &-head {
      padding: 0.1rem 0 2rem;
      width: 100%;
      font-size: var(--font-size);
      line-height: 1.7;
    }

    form {
      margin: 0;
      padding: 0;
    }

    fieldset {
      padding: 1rem 0 0;
      border: none;
      border-radius: 0px;
      display: flex;
      flex-wrap: wrap;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
    }

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 1rem;

      span {
        flex: 1;
        max-width: 100%;
        margin: 1rem 0 1rem;
        font-family: var(--font-family-head);
        font-size: calc(var(--font-size) * 0.8);
        font-weight: 400;
        color: #999;
        text-transform: uppercase;
      }

      input,
      textarea {
        flex: 1;
        font-family: var(--font-family);
        border: 1px solid var(--main-color);
        background-color: var(--bg-color);
        font-size: 16px;
        padding: 1rem;
        width: 100%;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
      }

      textarea {
        min-height: 16rem;
      }
    }

    button {
      border-color: var(--main-color);
      background-color: var(--bg-color);
      cursor: pointer;
      -webkit-appearance: none;
      border-radius: 0 24px 24px 0;
      -webkit-border-radius: 0 24px 24px 0;

      span {
        color: var(--main-color);
        font-family: var(--font-family);
        font-size: 1rem;
      }

      &:hover {
        span {
          color: var(--bg-color);
        }
        &::before {
          background-color: var(--main-color);
        }
      }
    }
  }
}
</style>
