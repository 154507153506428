<template>
  <nav
    id="navigation"
    :class="{ 'on-air': showNavbar, 'is-active': showMenu, 'theme-dark': navbarTheme }"
  >
    <a href="/" class="navigation__logo" @click="goToSection('home', $event)">
      <Logo class="navigation__logo-img" />
    </a>

    <ul class="navigation__menu" :class="{ 'on-air': showMenu }">
      <li v-for="item of menu" :key="item.slug" class="navigation__menu-item">
        <a
          :href="`/${item.slug}`"
          class="navigation__menu-link"
          @click="goToSection(item.id, $event)"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>

    <div class="navigation__menu-btn" :class="{ 'on-air': showMenu }" @click="showMenu = !showMenu">
      <span></span>
    </div>

    <a
      href="/kontakt"
      class="btn btn-contact"
      @click="goToSection('contact', $event)"
      v-if="!navbarTheme"
    >
      <span>Kontakt</span>
    </a>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/assets/Logo'

export default {
  name: 'Navigation',
  props: {
    showNavbar: Boolean,
    navbarTheme: Boolean,
  },

  data() {
    return {
      showMenu: false,
    }
  },

  components: {
    Logo
  },

  computed: {
    ...mapState({
      menu: (state) =>
        state.navigation.sections //.filter((item, i) => i > 0 && i < 6)
    })
  },

  methods: {
    goToSection(slug, event) {
      event.preventDefault()
      this.showMenu = false
      this.$store.dispatch('navigation/showSection', slug)
    }
  }
}
</script>

<style lang="scss">
#navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4vw 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 11px);
  z-index: 9999;
  transition: var(--transition);
  transform: translateY(-100%);
  height: 121px;
  color: var(--bg-color);
  background-color: transparent;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 91px;
    padding: 1rem 2rem !important;
  }

  &::after {
    content: '';
    background-color: currentColor;
    position: absolute;
    bottom: 0;
    left: 4vw;
    right: 4vw;
    height: 1px;
  }

  &.theme-dark {
    color: var(--main-color);
    background-color: var(--bg-color);

    .navigation__menu {
      @media screen and (min-width: 769px) {
        display: flex;
      }

      &-btn {
        color: var(--main-color);
      }
    }

    .btn-contact {
      span {
        color: var(--main-color);
      }

      &:hover {
        span {
          color: var(--bg-color);
        }
        &::before {
          background-color: var(--main-color);
        }
      }
    }
  }

  .btn-contact {
    transform: translateY(-50%);
    transition-delay: 0.2s;
    opacity: 0;
    color: var(--bg-color);
    border-color: var(--bg-color);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &.is-active {
    color: var(--main-color);
    background-color: var(--bg-color);
  }

  &.on-air {
    transform: translateY(0%);
    padding-bottom: 2rem;

    .btn-contact {
      transform: translateY(0%);
      opacity: 1;
      border-color: currentColor;
    }

    .navigation {
      &__logo,
      &__menu-item {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    &.full-menu {
      .navigation__menu {
        display: flex;
      }
    }
  }
}

@-moz-document url-prefix() {
  #navigation {
    width: calc(100% - 8px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.navigation {
  &__logo {
    height: 4rem;
    flex: 0 1;
    color: currentColor;
    transition: var(--transition);
    transition-delay: 0s;
    transform: translateY(-50%);
    opacity: 0;

    @media screen and (max-width: 768px) {
      height: 3rem;
    }

    &-img {
      height: 100%;
      fill: currentColor;
    }
  }

  &__menu {
    display: none;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 auto;
    overflow: hidden;
    transition: var(--transition);
    transition-delay: 0.1s;

    &.on-air {
      display: flex;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      top: 91px;
      background: var(--bg-color);
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--main-color);
      padding: 1rem 0;
    }

    &-btn {
      display: none;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: 0;
      position: relative;
      z-index: 99;
      color: var(--bg-color);
      border-top: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      cursor: pointer;
      transition: var(--transition);

      @media screen and (max-width: 768px) {
        display: flex;
      }

      &.on-air {
        height: 1.2rem;
        color: var(--main-color);
      }

      span {
        display: block;
        height: 1px;
        width: 100%;
        background: currentColor;
      }
    }

    &-item {
      opacity: 1;
      padding: 0 1.5rem;
      position: relative;
    }

    &-link {
      font-size: 1.2rem;
      color: currentColor;
      display: block;
      padding: 1rem 0;
      position: relative;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }

      &::after {
        content: '';
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0%;
        left: 0%;
        right: 100%;
        transition: var(--transition);
      }

      &:hover {
        left: 0%;
        right: 0%;

        &::after {
          animation-duration: 0.5s;
          animation-name: menulink;
          animation-iteration-count: 1;
          left: 0%;
          right: 0%;
        }
      }
    }
  }
}

@keyframes menulink {
  0% {
    left: 0%;
    right: 100%;
  }

  50% {
    left: 30%;
    right: 30%;
  }

  100% {
    left: 0%;
    right: 0%;
  }
}
</style>
