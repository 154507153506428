export default {
  namespaced: true,
  state: {
    sections: [
      { id: 'home', slug: 'home', title: 'Strona główna' },
      { id: 'about', slug: 'o-mnie', title: 'O mnie' },
      { id: 'realizations', slug: 'realizacje', title: 'Realizacje' },
      { id: 'projects', slug: 'projekty', title: 'Projekty' },
      { id: 'cooperation', slug: 'wspolpraca', title: 'Współpraca' },
      { id: 'contact', slug: 'kontakt', title: 'Kontakt' }
      // { id: 'partners', slug: 'partnerzy', title: 'Partnerzy' },
    ],
    scrollPosition: 0,
    showNavbar: true,
    darkTheme: false
  },

  mutations: {
    setPosition(state, position) {
      state.scrollPosition = position
    },
    setNavbar(state, status) {
      state.showNavbar = status
    },
    setTheme(state, theme) {
      state.darkTheme = theme
    }
  },

  actions: {
    showSection({ state, commit }, sectionId) {
      const section = document.getElementById(sectionId)
      if (!section) return
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth' })
        const page = state.sections.find((x) => x.id === sectionId)
        window.history.pushState({ showPage: sectionId }, page.title, page.slug)
      }, 200)

      setTimeout(() => {
        if (sectionId !== 'home') {
          commit('setNavbar', false)
        }
      }, 1000)
    }
  }
}
