<template>
  <a :href="link" class="btn btn-arrow" @click="clickAction($event)">
    <IconArrow width="1" />
  </a>
</template>

<script>
import IconArrow from '@/assets/IconArrow'

export default {
  name: 'ButtonArrow',
  props: {
    link: {
      type: String,
      default: '#'
    }
  },
  components: {
    IconArrow
  },
  methods: {
    clickAction(event) {
      event.preventDefault()
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss">
.btn.btn-arrow {
  border-color: var(--main-color);
  padding: 1rem;
  width: 4rem;

  svg {
    transition: var(--transition);
    position: relative;
    stroke: var(--main-color);
    z-index: 2;
  }

  &::before {
    background-color: var(--main-color);
  }

  &:hover {
    svg {
      stroke: var(--bg-color);
    }
  }
}
</style>
