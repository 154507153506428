export default {
  namespaced: true,
  state: {
    list: [
      {
        id: 1,
        slug: 'ciao-bella',
        title: 'Ciao Bella',
        city: 'Kraków',
        cover: 'cover-ciao-bella.jpg',
        amount: 29,
        author: 'fot. Justyna Stolarczyk'
      },
      {
        id: 2,
        slug: 'krakow-przewoz',
        title: 'Przewóz',
        city: 'Kraków',
        cover: 'cover-krakow-przewoz.jpg',
        amount: 45,
        author: 'fot. Justyna Stolarczyk'
      },
      {
        id: 3,
        slug: 'yana-sushi',
        title: 'Yana Sushi',
        city: 'Krakow',
        cover: 'cover-yana-sushi.jpg',
        amount: 33,
        author: 'fot. Justyna Stolarczyk'
      },
      {
        id: 4,
        slug: 'krakow-slaska',
        title: 'Śląska',
        city: 'Kraków',
        cover: 'cover-krakow-slaska.jpg',
        amount: 48,
        author: null
      },
      {
        id: 5,
        slug: 'myslenice-dabrowskiego',
        title: 'Dąbrowskiego',
        city: 'Myślenice',
        cover: 'cover-myslenice-dabrowskiego.jpg',
        amount: 8,
        author: null
      },
      {
        id: 6,
        slug: 'myslenice-sienkiewicza',
        title: 'Sienkiewicza',
        city: 'Myślenice',
        cover: 'cover-myslenice-sienkiewicza.jpg',
        amount: 14
      }
    ]
  },
  actions: {},
  mutations: {}
}
