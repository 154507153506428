<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.823"
    height="16.821"
    viewBox="0 0 10.823 16.821"
  >
    <g transform="translate(10.822 16.823) rotate(180)">
      <g transform="translate(9.411 15.411) rotate(180)">
        <line
          y1="6.981"
          x2="8"
          transform="translate(0 7.017)"
          fill="none"
          stroke-linecap="round"
          :stroke-width="width"
        />
        <path
          d="M0,0,7.921,7.035"
          transform="translate(0.079 0)"
          fill="none"
          stroke-linecap="round"
          :stroke-width="width"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '2'
    }
  }
}
</script>
