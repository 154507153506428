<template>
  <div id="tkwnetrza">
    <Navigation :showNavbar="showNavbar" :navbarTheme="darkTheme" />
    <Home id="home" />
    <About id="about" />
    <Realizations id="realizations" />
    <Projects id="projects" />
    <Cooperation id="cooperation" />
    <Contact id="contact" />
    <!--Partners id="partners" /-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Cooperation from '@/views/Cooperation.vue'
import Realizations from '@/views/Realizations.vue'
import Projects from '@/views/Projects.vue'
import Contact from '@/views/Contact.vue'
//import Partners from '@/views/Partners.vue'
import Navigation from './components/Navigation.vue'

export default {
  name: 'TKwnetrza',
  components: {
    Home,
    About,
    Cooperation,
    Realizations,
    Projects,
    Contact,
    // Partners,
    Navigation,
  },

  computed: {
    ...mapState({
      scrollPosition: (state) => state.navigation.scrollPosition,
      showNavbar: (state) => state.navigation.showNavbar,
      darkTheme: (state) => state.navigation.darkTheme
    })
  },

  methods: {
    onScroll() {
      const position = document.body.scrollTop
      if (position < 0) return
      if (Math.abs(position - this.scrollPosition) < 60) return

      this.$store.commit('navigation/setNavbar', position < this.scrollPosition)
      this.$store.commit('navigation/setPosition', position)
    }
  },

  mounted() {
    document.body.addEventListener('scroll', this.onScroll)
    const tk = document.getElementById('tkwnetrza')

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = entry.target.id
            console.log(section)
            if (section !== 'home') {
              // this.$store.commit('navigation/setNavbar', false)
              this.$store.commit('navigation/setTheme', true)
            } else {
              // this.$store.commit('navigation/setNavbar', true)
              this.$store.commit('navigation/setTheme', false)
            }

            setTimeout(() => {
              entry.target.classList.add('on-air')
            }, 100)
          } else {
            entry.target.classList.remove('on-air')
          }
        })
      },
      {
        threshold: (tk.offsetWidth > 768) ? 0.5 : 0.1
      }
    )

    const pages = document.querySelectorAll('.page')
    pages.forEach((page) => {
      observer.observe(page)
    })
  },

  beforeDestroy() {
    document.body.removeEventListener('scroll', this.onScroll)
  }
}
</script>


<style lang="scss">
@import '@/style/main.scss';
</style>
