<template>
  <div @click="clicked">
    <div class="gallery__slider-item-photo" v-if="ready">
      <img :src="file" :alt="this.title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryTile',
  props: {
    item: Number,
    gallery: String,
    folder: String,
  },

  data() {
    return {
      ready: false,
      file: null,
      title: null,
    }
  },

  methods: {
    clicked() {
      this.$emit('clicked', this.item.id)
    },

    async prepare() {
      const no = this.item < 10 ? '0' + this.item : this.item
      const filePath = `${this.gallery}-${no}.jpg`
      const file = await require(`@/assets/${this.folder}/${filePath}`)
      if (file) {
        this.ready = true
        this.file = file
      }
      this.title = this.gallery
    },
  },

  watch: {
    'item': function() {
      this.ready = false
      this.prepare()
    }
  },

  mounted() {
    this.prepare()
  }
}
</script>

<style lang="scss">
.gallery__slider-item {
  &-photo {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
</style>
