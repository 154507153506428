<template>
  <section class="page page__home">
    <article class="page__home-photo">
      <img src="@/assets/intro-bg-1.jpg" class="page__home-photo-img" />
      <div class="page__home-logo">
        <LogoPracownia class="page__home-logo-full" />
        <LogoPracowniaMobile class="page__home-logo-mobile" />
      </div>

      <!--div class="page__home-intro">
        <span>Lorem</span>
        <span>ipsum</span>
        <span>dolor</span>
        <span>sit</span>
        <span>amet</span>
        <span>enim</span>
        <span>etiam</span>
        <span>ultricies</span>
      </div-->
    </article>

    <a
      href="/o-mnie"
      class="btn btn-arrow-down"
      @click="goToSection('about', $event)"
    >
      <IconArrow width="1" />
    </a>
  </section>
</template>

<script>
import IconArrow from '@/assets/IconArrow'
import LogoPracownia from '@/assets/LogoPracownia'
import LogoPracowniaMobile from '@/assets/LogoPracowniaMobile'

export default {
  name: 'Home',
  components: {
    IconArrow,
    LogoPracownia,
    LogoPracowniaMobile,
  },
  methods: {
    goToSection(slug, event) {
      event.preventDefault()
      this.$store.dispatch('navigation/showSection', slug)
    }
  }
}
</script>

<style lang="scss">
.btn.btn-arrow-down {
	position: absolute;
	z-index: 99;
	bottom: 50px;
	left: 50%;
	padding: 0;
	transform: rotate(90deg) translate(-20px, 24px);
	width: 48px;
	height: 42px;
	border-radius: 0 24px 24px 0;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 0;
    height: 42px;
    border-radius: inherit;
    background-color: var(--bg-color);
    transition: var(--transition);
    z-index: 1;
  }

  svg {
    transition: var(--transition);
    position: relative;
    stroke: var(--bg-color);
    z-index: 2;
  }

  &:hover {
    svg {
      stroke: var(--main-color);
    }
  }
}

.page__home {
  height: 100vh;
  &.page {
    @media screen and (max-width: 768px) {
      height: 100vh;
    }
  }

  &-photo {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    color: var(--bg-color);
    z-index: 99;
    transition: var(--transition);
    opacity: 0;
    transition-delay: 0.5s;

    &-full {
      display: block;
      height: 10rem;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }


    &-mobile {
      display: none;
      height: 9rem;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  &-intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 4rem;
    max-width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 2;
    line-height: 1.1;

    span {
      position: relative;
      z-index: 3;
      padding: 0 0.35rem;
      color: var(--bg-color);
      opacity: 0;
      transform: translateY(-1rem);
      transition: var(--transition);

      &::selection {
        background-color: var(--bg-color);
        color: var(--main-color);
      }
    }

    @for $i from 1 through 15 {
      span:nth-child(#{$i}n) {
        transition-delay: #{0.5 + ($i * 0.1)}s;
      }
    }
  }

  $page: &;
  &.on-air {
    #{$page}-logo {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    #{$page}-intro span {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
