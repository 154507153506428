<template>
  <section class="page page__realizations">
    <div class="page__realizations-head">
      <h2>Realizacje</h2>
    </div>

    <div class="page__realizations-arrow">
      <ButtonArrow @click="showPrev()" class="btn-arrow-left" />
    </div>

    <div class="page__realizations-wrapper">
      <div class="page__realizations-container">
        <TileItem
          v-for="item of list"
          :key="item.slug"
          class="page__realizations-item"
          :item="item"
          @clicked="showGallery(item.id)"
        />
      </div>
    </div>

    <div class="page__realizations-arrow">
      <ButtonArrow @click="showNext()" class="btn-arrow-right" />
    </div>

    <div v-if="galleryId" class="page__realizations-gallery">
      <Gallery :gallery="gallery" folder="realizations" @close="closeGallery" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import TileItem from '@/components/TileItem'
import ButtonArrow from '@/components/ButtonArrow'
import Gallery from '@/components/Gallery'

export default {
  name: 'Realizations',
  components: {
    ButtonArrow,
    TileItem,
    Gallery
  },

  data() {
    return {
      activeItem: 1,
      galleryId: null
    }
  },

  computed: {
    ...mapState({
      list: (state) => state.realizations.list,
      lastItem: (state) => {
        const width = document.getElementById('tkwnetrza').offsetWidth
        return state.realizations.list.length - (width > 768 ? 2 : 0)
      },
      gallery: function (state) {
        return this.galleryId
          ? state.realizations.list.find((i) => i.id === this.galleryId)
          : null
      }
    })
  },

  methods: {
    closeGallery() {
      this.galleryId = null
      window.history.back(1)
    },
    showGallery(id) {
      this.galleryId = id
      const galleryItem = this.list.find((i) => i.id === id)
      window.history.pushState(
        { showRealization: id },
        galleryItem.title,
        `realizacje/${galleryItem.slug}`
      )
    },

    showPrev() {
      const nextItem = this.activeItem - 1
      if (nextItem >= 1) this.showItem(nextItem)
    },
    showNext() {
      const nextItem = this.activeItem + 1
      if (nextItem <= this.lastItem) this.showItem(nextItem)
    },

    showItem(item) {
      const itemWidth = document.querySelector('.page__realizations-item')
        .offsetWidth
      const container = document.querySelector('.page__realizations-container')
      const newPosition = (itemWidth * item - itemWidth) * -1
      container.style.transform = `translateX(${newPosition}px`
      this.activeItem = item
    }
  }
}
</script>

<style lang="scss">
.page__realizations {
  padding: 0 4vw 5vw;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    padding: 2rem;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 4vw);
    left: 4vw;
    width: calc(100% - 8vw);
    height: 8vw;
    border-top: 1px solid var(--main-color);
  }

  &-head {
    padding: 0 5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    h2 {
      margin: 0 !important;
    }
  }

  &-arrow {
    max-width: 4rem;
    padding-bottom: 10rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 4;

    @media screen and (max-width: 768px) {
      padding-bottom: 5rem;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 4rem;
      justify-content: center;
    }
    &:last-child {
      @media screen and (max-width: 768px) {
        left: calc(100% - 4rem);
      }
    }

    .btn {
      &-arrow-left {
        transform: rotate(180deg);
      }

      &.btn-arrow {
        @media screen and (max-width: 768px) {
          padding: 0.4rem 0rem;
          width: 2rem;
        }
      }
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 8vw);
    overflow: hidden;
    width: calc(100% - 8rem);
    flex: 1;

    @media screen and (max-width: 768px) {
      width: calc(100% + 4rem);
      margin: 1rem -2rem 0;
      height: auto;
    }
  }

  &-container {
    flex: 1 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    transition: var(--transition);
    transform: translateX(0px);
  }

  &-item {
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;

    @media screen and (max-width: 768px) {
      min-width: 100%;
      max-width: 100%;
      padding: 0;
    }

    .tile__item-photo {
      @media screen and (max-width: 768px) {
        max-height: 250px;
      }
      img {
        @media screen and (max-width: 768px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
