<template>
  <div @click="clicked">
    <div class="tile__item-photo">
      <img :src="require(`@/assets/photos/${item.cover}`)" :alt="item.title" />
    </div>
    <div class="tile__item-desc">
      <h3 class="tile__item-title">
        {{ item.title }}
      </h3>
      <h4 class="tile__item-city">{{ item.city }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TileItem',
  props: {
    item: Object
  },

  methods: {
    clicked() {
      this.$emit('clicked', this.item.id)
    }
  }
}
</script>

<style lang="scss">
.tile__item {
  &-photo {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.9);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.4s ease;
      opacity: 1;
    }

    &:hover {
      img {
        height: 110%;
        opacity: 0.5;
      }
    }
  }

  &-desc {
    min-height: 6rem;
    max-height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 1 1 auto;

    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
  }

  &-title {
    margin: 0;
    font-weight: 400;
    font-size: var(--font-size);
  }
  &-city {
    margin: 0;
    font-weight: 400;
    font-size: var(--font-size);
  }
}
</style>
