export default {
  namespaced: true,
  state: {
    list: [
      {
        id: 1,
        slug: 'krakow-malczewskiego',
        title: 'Malczewskiego',
        city: 'Kraków',
        cover: 'cover-krakow-malczewskiego.jpg',
        amount: 10,
      },
      {
        id: 2,
        slug: 'katowice-ceglana',
        title: 'Ceglana',
        city: 'Katowice',
        cover: 'cover-kato-ceglana.jpg',
        amount: 12,
      },
      {
        id: 3,
        slug: 'krakow-armii-krajowej',
        title: 'Armii Krajowej',
        city: 'Kraków',
        cover: 'cover-krk-ak.jpg',
        amount: 16,
      },
      {
        id: 4,
        slug: 'szklary-dom',
        title: 'Dom jednorodzinny',
        city: 'Szklary',
        cover: 'cover-szklary-dom.jpg',
        amount: 32,
      },
      {
        id: 5,
        slug: 'myslenice-salon',
        title: 'Salon fryzjerski',
        city: 'Myślenice',
        cover: 'cover-mc-salon.jpg',
        amount: 10,
      },
      {
        id: 6,
        slug: 'brzezie-zarzecze',
        title: 'Dom jednorodzinny',
        city: 'Brzezie',
        cover: 'cover-brzezie-zarzecze.jpg',
        amount: 22,
      },
      {
        id: 7,
        slug: 'wolica-dom',
        title: 'Dom jednorodzinny',
        city: 'Wolica',
        cover: 'cover-wolica-dom.jpg',
        amount: 14,
      },
      {
        id: 8,
        slug: 'wolica-dom2',
        title: 'Dom jednorodzinny 2',
        city: 'Wolica',
        cover: 'cover-wolica-dom2.jpg',
        amount: 26,
      },
      {
        id: 9,
        slug: 'komorniki-dom',
        title: 'Dom jednorodzinny',
        city: 'Komorniki',
        cover: 'cover-komorniki-dom.jpg',
        amount: 22,
      },
      {
        id: 10,
        slug: 'myslenice-sienkiewicza',
        title: 'Dom Sienkiewicza',
        city: 'Myślenice',
        cover: 'cover-mc-sienkiewicza.jpg',
        amount: 10,
      },
      {
        id: 11,
        slug: 'myslenice-zarabie',
        title: 'Zarabie',
        city: 'Myślenice',
        cover: 'cover-myslenice-zarabie.jpg',
        amount: 17,
      },
      {
        id: 12,
        slug: 'krakow-petrazyckiego',
        title: 'Petrażyckiego',
        city: 'Kraków',
        cover: 'cover-krakow-petrazyckiego.jpg',
        amount: 20,
      },
      {
        id: 13,
        slug: 'sulkowice-partyzantow',
        title: 'Partyzantów',
        city: 'Sułkowice',
        cover: 'cover-sulkowice-partyzantow.jpg',
        amount: 14,
      },
      {
        id: 14,
        slug: 'krakow-slomczynskiego',
        title: 'Słomczyńskiego',
        city: 'Kraków',
        cover: 'cover-krakow-slomczynskiego.jpg',
        amount: 12,
      },
      {
        id: 15,
        slug: 'krakow-senatorska',
        title: 'Senatorska',
        city: 'Kraków',
        cover: 'cover-krakow-senatorska.jpg',
        amount: 9,
      },
      {
        id: 16,
        slug: 'krakow-bialoruska',
        title: 'Białoruska',
        city: 'Kraków',
        cover: 'cover-krakow-bialoruska.jpg',
        amount: 10,
      },
      {
        id: 17,
        slug: 'pcim-dom',
        title: 'Dom jednorodzinny',
        city: 'Pcim',
        cover: 'cover-pcim-dom.jpg',
        amount: 13,
      },
      {
        id: 18,
        slug: 'krakow-kurdwanow',
        title: 'Kurdwanów',
        city: 'Kraków',
        cover: 'cover-krakow-kurdwanow.jpg',
        amount: 10,
      },
      {
        id: 19,
        slug: 'myslenice-mostowa',
        title: 'Mostowa',
        city: 'Myślenice',
        cover: 'cover-myslenice-mostowa.jpg',
        amount: 14,
      },
      {
        id: 20,
        slug: 'krakow-siemiradzkiego',
        title: 'Siemiradzkiego',
        city: 'Kraków',
        cover: 'cover-krakow-siemiradzkiego.jpg',
        amount: 11,
      },
      {
        id: 21,
        slug: 'skrzynka-mieszkanie',
        title: 'Mieszkanie',
        city: 'Skrzynka',
        cover: 'cover-skrzynka-mieszkanie.jpg',
        amount: 11,
      },
      {
        id: 22,
        slug: 'brzaczowice-dom',
        title: 'Dom jednorodzinny',
        city: 'Brzączowice',
        cover: 'cover-brzaczowice-dom.jpg',
        amount: 25,
      },


    ]
  },
  actions: {
  },
  mutations: {
  }
}
