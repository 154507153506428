<template>
  <section class="page page__cooperation">
    <div class="page__cooperation-head">
      <h2>Współpraca</h2>
    </div>
    <article class="page__cooperation-article">
      <div class="page__cooperation-article-section">
        Aby otrzymać bezpłatna wycenę projektu prześlij do nas rzut architektoniczny wraz z krótkim opisem odnośnie preferowanego stylu swojego wnętrza poprzez formularz kontaktowy lub na podany adres e-mail. Jeśli nie posiadasz dokumentacji technicznej swojego wnętrza możesz umówić się na spotkanie na inwestycji gdzie dokonam inwentaryzacji wnętrza jak również analizy potrzeb i przygotuje indywidualna ofertę.
        Po zaakceptowaniu oferty projekt podzielony jest na następujące etapy:
      </div>
      <div class="page__cooperation-article-column">
        <h3>Etap I</h3>
        <ul class="page__cooperation-article-list">
          <li>Inwentaryzacja wnętrza.</li>
          <li>Aranżacja 2D.</li>
          <li>Przygotowanie modelu 3D oraz wizualizacji koncepcyjnych wnętrza.</li>
          <li>Spotkanie z klientem w celu omówienia projektu oraz zaproponowanych rozwiązań i materiałów.</li>
          <li>Po wykonaniu etapu klient ma mozliwosc wprowadzenia zmian.</li>
        </ul>
      </div>

      <div class="page__cooperation-article-column">
        <h3>Etap II</h3>
        <ul class="page__cooperation-article-list">
          <li>Przygotowanie pełnej dokumentacji technicznej.</li>
          <li>Przygotowanie zestawień materiałów.</li>
          <li>Koordynacja zamówień.</li>
          <li>Przygotowanie ofert firm współpracujących.</li>
        </ul>
      </div>

      <div class="page__cooperation-article-column">
        <h3>Etap III</h3>
        <ul class="page__cooperation-article-list">
          <li>Nadzór autorski.</li>
        </ul>
        <p>
          Opcja dodatkowa dla osób którym zależy na poprowadzeniu całej inwestycji od projektu
          po oddanie gotowego wnętrza. Rozpoczyna się od zamówień materiałów.
          Zakres pracy projektanta to weryfikacja zgodności prac z dokumentacja projektowa.
          Nadzór montaży, dostaw jak również doposażenie inwestycji w drobne materiały dekoracyjne na życzenie klienta.
        </p>
      </div>

    </article>
  </section>
</template>

<script>
export default {
  name: 'Cooperation'
}
</script>

<style lang="scss">
.page__cooperation {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;
  align-content: center;
  padding: 0 4vw 6vw;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }

  &-head {
    padding: 0 4rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    min-width: 100%;
    flex: 1;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    h2 {
      margin: 0 !important;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 4vw);
    left: 4vw;
    width: calc(100% - 8vw);
    height: 8vw;
    border-top: 1px solid var(--main-color);
  }

  &-article {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    min-width: 100%;
    padding: 2rem 4rem;

    @media screen and (max-width: 768px) {
      padding: 2rem 0;
    }

    &-section {
      flex: 1;
      min-width: 100%;
      line-height: 1.7;
      font-size: var(--font-size);
      color: var(--main-color);
    }

    &-column {
      flex: 1;
      max-width: 30%;
      margin: 1rem 0;
      line-height: 1.7;
      font-size: var(--font-size);
      color: var(--main-color);

      @media screen and (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0 0 0 2rem;
        position: relative;

        &::before {
          content: '\2014';
          position: absolute;
          left: 0;
          top: 0%;
          transform: translateY(30%);
          height: 1rem;
          display: flex;
          align-items: center;

        }
      }
    }
  }
}
</style>
