<template>
  <div class="gallery__slider" :class="{ 'is--active': gallery }">
    <div class="gallery__slider-close" @click="closeGallery">
      <span></span>
      <span></span>
    </div>

    <div class="gallery__slider-arrow">
      <ButtonArrow @click="slidePrev()" class="btn-arrow-left" />
    </div>

    <div class="gallery__slider-wrapper">
      <div class="gallery__slider-container">
        <GalleryTile
          class="gallery__slider-item"
          :item="slideItem"
          :gallery="gallery.slug"
          :folder="folder"
        />
      </div>
      <div class="gallery__slider-author" v-if="gallery.author">
        {{ gallery.author }}
      </div>
    </div>

    <div class="gallery__slider-arrow">
      <ButtonArrow @click="slideNext()" class="btn-arrow-right" />
    </div>
  </div>
</template>

<script>
import ButtonArrow from '@/components/ButtonArrow'
import GalleryTile from '@/components/GalleryTile'

export default {
  name: 'Gallery',
  components: {
    ButtonArrow,
    GalleryTile
  },

  props: {
    gallery: Object,
    folder: String
  },

  data() {
    return {
      slideItem: 1,
      onAir: false
    }
  },

  methods: {
    closeGallery() {
      this.$emit('close')
    },

    slidePrev() {
      const nextItem = this.slideItem - 1
      const lastItem = this.gallery.amount
      this.showItem(nextItem >= 1 ? nextItem : lastItem)
    },
    slideNext() {
      const nextItem = this.slideItem + 1
      const lastItem = this.gallery.amount
      this.showItem(nextItem <= lastItem ? nextItem : 1)
    },

    showItem(item) {
      this.slideItem = item
    }
  }
}
</script>

<style lang="scss">
.gallery {
  &__slider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: var(--bg-color);
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 2rem;

    @media screen and (max-width: 768px) {
      padding: 1rem;
    }

    &.is--active {
      opacity: 1;
    }

    &-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      border: none;
      z-index: 99;

      span {
        display: block;
        height: 1px;
        width: 100%;
        background: var(--main-color);
        transform-origin: 50% 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transition: var(--transition);
        transform: rotate(-45deg);

        &:last-child {
          transform: rotate(45deg);
        }
      }

      &:hover {
        span {
          transform: rotate(45deg);

          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-arrow {
      position: relative;
      z-index: 9;

      .btn {
        &-arrow-left {
          transform: rotate(180deg);
        }

        &.btn-arrow {
          @media screen and (max-width: 768px) {
            padding: 0.4rem 0rem;
            width: 2rem;
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 8vw);
      overflow: hidden;
      width: calc(100% - 8rem);
      flex: 1;
      position: relative;
      z-index: 3;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0 -3rem;
      }
    }

    &-author {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-family: var(--font-family-head);
      font-size: calc(var(--font-size) * 0.8);
      font-weight: 400;
      color: #999;
      text-transform: uppercase;
      text-align: center;
    }

    &-container {
      flex: 1 100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      transition: var(--transition);
      transform: translateX(0px);
    }

    &-item {
      // max-width: calc(calc(100vw - 8vw - 6.5rem) / 4);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 5rem;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }
}
</style>
