<template>
  <section class="page page__about">
    <div class="page__about-content">
      <div class="page__about-photo" :style="`background-image: url(${image})`">
        <!--img
        src="@/assets/klaudia-tomczyk.jpg"
        alt="Klaudia Tomczyk"
        class="page__about-photo-image"
      /-->
      </div>
      <article class="page__about-article">
        <h2>o mnie</h2>
        <p>
          Głównym założeniem działalności pracowni jest projektowanie wysokiej
          jakości wnętrz prywatnych, komercyjnych jak również projektów mebli.
          Moja firma oficjalnie rozpoczęła działalność w 2016 roku nie mniej już
          we wcześniejszych latach zdobywałam wiedzę i doświadczenie w
          specjalistycznych pracowniach wnętrz oraz podczas pracy w zakładach
          stolarskich. Dzięki tej pracy udało mi się dokładnie zapoznać z
          procesem tworzenia mebli oraz innych elementów dekoracyjnych. Zdobyte
          tam doświadczenie, znajomość materiałów i procesu ich obróbki
          przełożyło się na jakość mojej teraźniejszej pracy.
        </p>
        <p>
          Wykształcenie kierunkowe zdobyłam również między innymi na Wydziale
          Architektury wnętrz Akademii Sztuk Pięknych w Krakowie, a przez
          kolejne lata uczestniczyłam w wielu kursach i szkoleniach branżowych.
          W swojej pracy od samego początku stawiam na jak najlepsza komunikacje
          z klientem oraz staram się aby każde wnętrze było dopasowane do jego
          potrzeb oraz stylu. Zaufanie klientów przełożyło się na wiele
          długoletnich współprac przy nowych projektach.
        </p>
        <p>
          Jeśli chcesz dowiedzieć sie więcej odnośnie procesu powstawania
          projektu, jesteś zainteresowany wstępną wyceną zapraszam do działu
          współpraca lub bezpośredniego formularza kontaktowego w dziale
          kontakt.
        </p>

        <div class="page__buttons">
          <a
            href="/wspolpraca"
            class="btn btn-action"
            @click="goToSection('cooperation', $event)"
          >
            <span>Współpraca</span>
          </a>

          <a
            href="/kontakt"
            class="btn btn-action"
            @click="goToSection('contact', $event)"
          >
            <span>Kontakt</span>
          </a>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      image: require('@/assets/klaudia-tomczyk.jpg')
    }
  },

  methods: {
    goToSection(slug, event) {
      event.preventDefault()
      console.log(slug)
      this.$store.dispatch('navigation/showSection', slug)
    }
  }
}
</script>

<style lang="scss">
.page__about {
  display: flex;
  justify-content: center; // space-between;
  align-items: center;
  padding: 5rem 5rem 9rem;
  position: relative;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
  }

  @media screen and (max-width: 1080px) {
    padding: 4rem 2rem 7rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 4vw);
    left: 4vw;
    width: calc(100% - 8vw);
    height: 9vw;
    border-top: 1px solid var(--main-color);
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1200px;

    @media screen and (min-width: 2000px) {
      max-width: 1400px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      max-width: 90%;
    }
  }

  &-photo {
    flex: 1;
    max-width: 30%;
    padding: 0rem;
    background-position: 50% 50%;
    background-size: cover;

    @media screen and (min-width: 2000px) {
      max-width: 40%;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      max-height: unset;
      min-height: 30rem;
      height: 30rem;
      margin: 2rem 0 4rem;
    }

    &-image {
      max-width: 100%;
      max-height: 30rem;
      flex: 0;

      @media screen and (min-width: 2000px) {
        max-height: 30rem;
      }
    }
  }

  &-article {
    flex: 1;
    max-width: 70%;
    padding-left: 5rem;

    @media screen and (min-width: 1200px) {
      padding-left: 6rem;
    }
    @media screen and (min-width: 2000px) {
      max-width: 60%;
      padding-left: 8rem;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 2rem 0;
    }

    p {
      margin: 1rem 0;
      line-height: 1.7;
      font-size: var(--font-size);
      color: var(--main-color);
      font-weight: 400;
    }
  }
}
</style>
